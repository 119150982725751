window.jQuery = window.$ = require('jquery');

require('bootstrap');
require('jquery-colorbox');
require('slick-carousel');

$(function() {
	function toggleNav(){
	  var chkbox = document.getElementById("mobile-toggle");
	  chkbox.checked = !chkbox.checked;
      //console.log('rest');
        if(chkbox.checked) $('.mobile-toggle').addClass('opening');
        else $('.mobile-toggle').removeClass('opening');
	}
	var nav = document.getElementById('main-nav');
    nav.addEventListener('click', toggleNav);

});

$('a.display-image').colorbox({maxWidth:'95%',maxHeight:'95%'});

import AOS from 'aos';
AOS.init();

$('.crafty-block-slideshow').slick({
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 600
});
